import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/Layout"
import "./Categoria.css"
import Products from "./productos.json"

export default () => {
  return (
    <Layout type="HMNF" className="category" title="category">
      <h1 className="category-title">OUTERWEAR</h1>
      <div className="jeans">
        {Products.slice(0, 8).map((item, i) => {
          return (
            <div className="jean" key={i}>
              {/* <picture> */}
              <img
                className="jean-img"
                src={item.img}
                loading="lazy"
                alt="img"
              />
              {/* </picture> */}
              <div className="jean-title">Medium Waist Skinny</div>
            </div>
          )
        })}
      </div>
      <div className="cards">
        {Products.map((item, i) => {
          return (
            <Link to="/categoria/producto" className="card" key={i}>
              <picture>
                <img
                  className="card-img-back"
                  src={item.img}
                  loading="lazy"
                  alt="img"
                />
                <img
                  className="card-img-front"
                  src={item.img}
                  loading="lazy"
                  alt="img2"
                />
              </picture>
              <div className="card-title">{item.nombre}</div>
              <div className="card-price">{item.precio}</div>
              <div className="card-colors">+ 4 Colores</div>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}
